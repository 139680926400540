<template>
  <div class="error-page">
    <div v-if="error.statusCode === 404" class="error-page__error">
      <h1>404</h1>
      <p>Die aufgerufene Seite konnte nicht gefunden werden.</p>
    </div>
    <div v-else class="error-page__error">
      <h1>Ein Fehler ist aufgetreten</h1>
    </div>
    <LinkButton :link="{ name: 'index' }"> zur Startseite </LinkButton>
  </div>
</template>

<script>
import LinkButton from '@/components/ui/button/link-button'

export default {
  components: {
    LinkButton,
  },
  layout: 'default',
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '../css/import/vars';

.error-page {
  width: 100%;
  max-width: $page-max-width;
  min-width: $page-min-width;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6rem 1rem;

  &__error {
    margin-bottom: 20px;
    text-align: center;
  }

  h1 {
  }

  p {
    margin-bottom: 20px;
  }
}
</style>
