import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _09fd8459 = () => interopDefault(import('../pages/abo/index.vue' /* webpackChunkName: "pages/abo/index" */))
const _829ac038 = () => interopDefault(import('../pages/agb.vue' /* webpackChunkName: "pages/agb" */))
const _33af0490 = () => interopDefault(import('../pages/benutzerkonto.vue' /* webpackChunkName: "pages/benutzerkonto" */))
const _36c7d67a = () => interopDefault(import('../pages/booklet.vue' /* webpackChunkName: "pages/booklet" */))
const _73e8d823 = () => interopDefault(import('../pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _1e89048b = () => interopDefault(import('../pages/datenschutz.vue' /* webpackChunkName: "pages/datenschutz" */))
const _4afee9e2 = () => interopDefault(import('../pages/epaper.vue' /* webpackChunkName: "pages/epaper" */))
const _415945df = () => interopDefault(import('../pages/impressum.vue' /* webpackChunkName: "pages/impressum" */))
const _65022681 = () => interopDefault(import('../pages/newsletter.vue' /* webpackChunkName: "pages/newsletter" */))
const _607df160 = () => interopDefault(import('../pages/offenlegung.vue' /* webpackChunkName: "pages/offenlegung" */))
const _7db133ca = () => interopDefault(import('../pages/registered.vue' /* webpackChunkName: "pages/registered" */))
const _539194bc = () => interopDefault(import('../pages/subscribed.vue' /* webpackChunkName: "pages/subscribed" */))
const _ae2180c0 = () => interopDefault(import('../pages/subscription-error.vue' /* webpackChunkName: "pages/subscription-error" */))
const _e30b7e34 = () => interopDefault(import('../pages/suche.vue' /* webpackChunkName: "pages/suche" */))
const _23ca5914 = () => interopDefault(import('../pages/ueber-uns.vue' /* webpackChunkName: "pages/ueber-uns" */))
const _1d6f8b28 = () => interopDefault(import('../pages/widerruf.vue' /* webpackChunkName: "pages/widerruf" */))
const _1d7920ba = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0846e311 = () => interopDefault(import('../pages/abo/_slug.vue' /* webpackChunkName: "pages/abo/_slug" */))
const _a5ce1556 = () => interopDefault(import('../pages/archive/_id.vue' /* webpackChunkName: "pages/archive/_id" */))
const _f97924ee = () => interopDefault(import('../pages/article/_id.vue' /* webpackChunkName: "pages/article/_id" */))
const _6bd6ce7e = () => interopDefault(import('../pages/author/_id.vue' /* webpackChunkName: "pages/author/_id" */))
const _cbf76d34 = () => interopDefault(import('../pages/channel/_slug.vue' /* webpackChunkName: "pages/channel/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/abo",
    component: _09fd8459,
    name: "abo"
  }, {
    path: "/agb",
    component: _829ac038,
    name: "agb"
  }, {
    path: "/benutzerkonto",
    component: _33af0490,
    name: "benutzerkonto"
  }, {
    path: "/booklet",
    component: _36c7d67a,
    name: "booklet"
  }, {
    path: "/cookie-policy",
    component: _73e8d823,
    name: "cookie-policy"
  }, {
    path: "/datenschutz",
    component: _1e89048b,
    name: "datenschutz"
  }, {
    path: "/epaper",
    component: _4afee9e2,
    name: "epaper"
  }, {
    path: "/impressum",
    component: _415945df,
    name: "impressum"
  }, {
    path: "/newsletter",
    component: _65022681,
    name: "newsletter"
  }, {
    path: "/offenlegung",
    component: _607df160,
    name: "offenlegung"
  }, {
    path: "/registered",
    component: _7db133ca,
    name: "registered"
  }, {
    path: "/subscribed",
    component: _539194bc,
    name: "subscribed"
  }, {
    path: "/subscription-error",
    component: _ae2180c0,
    name: "subscription-error"
  }, {
    path: "/suche",
    component: _e30b7e34,
    name: "suche"
  }, {
    path: "/ueber-uns",
    component: _23ca5914,
    name: "ueber-uns"
  }, {
    path: "/widerruf",
    component: _1d6f8b28,
    name: "widerruf"
  }, {
    path: "/",
    component: _1d7920ba,
    name: "index"
  }, {
    path: "/abo/:slug",
    component: _0846e311,
    name: "abo-slug"
  }, {
    path: "/archive/:id?",
    component: _a5ce1556,
    name: "archive-id"
  }, {
    path: "/article/:id?",
    component: _f97924ee,
    name: "article-id"
  }, {
    path: "/author/:id?",
    component: _6bd6ce7e,
    name: "author-id"
  }, {
    path: "/channel/:slug?",
    component: _cbf76d34,
    name: "channel-slug"
  }, {
    path: "/autor/:authorName([a-z0-9A-F%-]+)-:id(\\d+)",
    component: _6bd6ce7e,
    name: "author-canonical"
  }, {
    path: "/:channelSlugs([a-z0-9A-F%-]+)+/:slug([a-z0-9A-F%-]+)-:id(\\d+)",
    component: _f97924ee,
    name: "article-canonical"
  }, {
    path: "/:channelSlugs([a-z0-9A-F%-]+)*/:slug([a-z0-9A-F%-]+)",
    component: _cbf76d34,
    name: "channel-canonical"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
