const loadPixel = ($config) => {
  if (!$config.pixelId) {
    // eslint-disable-next-line no-console
    console.warn('Facebook pixel is not available in this environment')
    return
  }

  /* eslint-disable */
  // prettier-ignore
  // https://developers.facebook.com/docs/facebook-pixel/implementation
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return; n = f.fbq = function () {
      n.callMethod
        ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    }
    if (!f._fbq)f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0'
    n.queue = []; t = b.createElement(e); t.async = !0
    t.src = v; s = b.getElementsByTagName(e)[0]
    s.parentNode.insertBefore(t, s)
  }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js'))

  fbq('init', $config.pixelId)
  fbq('track', 'PageView')
  /* eslint-enable */

  return window.fbq
}

export const actions = {
  init({ dispatch, rootGetters }) {
    this.watch(
      () => rootGetters['consent/hasFacebookConsent'],
      (hasConsent) => {
        hasConsent ? dispatch('enableFacebookPixel') : dispatch('disableFacebookPixel')
      }
    )
  },
  enableFacebookPixel() {
    window.fbq ? window.fbq('consent', 'grant') : loadPixel(this.$config)
  },
  disableFacebookPixel() {
    window.fbq && window.fbq('consent', 'revoke')
  },
  initCustomPixelEvents() {
    const { tp, fbq } = window

    if (!tp || !fbq) {
      return
    }

    tp.push(
      [
        'addHandler',
        'loginSuccess',
        () => {
          fbq('trackCustom', 'LoginSuccess')
        },
      ],
      [
        'addHandler',
        'showTemplate',
        () => {
          fbq('trackCustom', 'ShowTemplate')
        },
      ],
      [
        'addHandler',
        'showOffer',
        () => {
          fbq('track', 'Lead')
        },
      ],
      [
        'addHandler',
        'startCheckout',
        () => {
          fbq('track', 'InitiateCheckout')
        },
      ],
      [
        'addHandler',
        'checkoutSelectTerm',
        () => {
          fbq('trackCustom', 'CheckoutSelectTerm')
        },
      ],
      [
        'addHandler',
        'checkoutComplete',
        () => {
          fbq('trackCustom', 'CheckoutComplete')
        },
      ],
      [
        'addHandler',
        'checkoutClose',
        () => {
          fbq('trackCustom', 'CheckoutClose')
        },
      ],
      [
        'addHandler',
        'checkoutError',
        () => {
          fbq('trackCustom', 'CheckoutError')
        },
      ],
      [
        'addHandler',
        'submitPayment',
        ({ term }) => {
          const { chargeAmount, chargeCurrency, termId } = term

          if (!chargeAmount) {
            fbq('trackCustom', 'FreePurchase')
          } else {
            fbq('track', 'Purchase', {
              currency: chargeCurrency,
              value: chargeAmount,
              content_ids: [termId],
              content_name: termId,
              content_type: 'product',
            })
          }
        },
      ]
    )
  },
}
