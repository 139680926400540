<template>
  <div v-if="adsEnabled && showAdPosition" class="skyscraper" :id="desktopId"></div>
</template>

<script>
export default {
  props: {
    desktopId: {
      type: String,
      required: true,
    },
  },
  computed: {
    adsEnabled() {
      return this.$config.adsEnabled
    },
    noAdPositions() {
      return this.$store.getters['cope/noAdPositions']
    },
    showAdPosition() {
      return !this.noAdPositions.map((element) => element.formatId).includes(this.desktopId.replace('sas_', ''))
    },
  },
}
</script>

<style lang="scss">
@import '../../css/import/vars';
.skyscraper {
  display: none;

  @media all and (min-width: $screen-width-767) {
    display: block;
    margin-top: 20px;
    max-width: 300px;
    max-height: 600px;
    position: sticky;
    top: 20px;
  }
}
</style>
