import Vue from 'vue'

// localStorage key
const FLAG_KEY = '__feature_timeline'

Vue.mixin({
  methods: {
    /**
     * @description Determine feature flag status, allow override in
     * localStorage on client only
     */
    $featureTimeline() {
      // On SSR always use env setting
      if (process.server) {
        return this.$config.featureTimeline
      }
      // Only update localStorage if queryparameter was set
      const queryParam =
        typeof this.$route.query.feature_timeline !== 'undefined' ? this.$route.query.feature_timeline : null
      if (queryParam === '1') {
        global.localStorage.setItem(FLAG_KEY, '1')
      } else if (queryParam !== null) {
        global.localStorage.removeItem(FLAG_KEY)
      }
      // Only if key exists and is '1' in localStorage return true
      if (global.localStorage.getItem(FLAG_KEY) === '1') {
        return true
      }
      // Else return server setting
      return this.$config.featureTimeline
    },
  },
})
