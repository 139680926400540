<template>
  <div id="inline-container-sticky-banner"></div>
</template>

<script>
export default {}
</script>

<style lang="scss">
#inline-container-sticky-banner {
  display: flex;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 10;
  left: 0;
  right: 0;
  margin: 0 auto;
}
</style>
