import { loadScript } from './load-script'

export async function loadMatomo() {
  const _mtm = (window._mtm = window._mtm || [])
  // `_paq` comes from here: https://developer.matomo.org/guides/tracking-consent
  const _paq = (window._paq = window._paq || [])
  _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' })
  _paq.push(['requireConsent'])

  window.tp = window.tp || []
  _paq.push(['setConsentGiven'])
  loadScript({ src: 'https://cdn.matomo.cloud/furche.matomo.cloud/container_m8uwUgzS.js' })
}
