// vendor identifiers provided by Didomi
export const DIDOMI_VENDOR_IDS = {
  PIANO: 'c:pianosoft-KFChz6mr',
  FACEBOOK: 'facebook',
  INSTAGRAM: 'c:instagram',
  YOUTUBE: 'c:youtube',
  TWITTER: 'twitter',
  SOUNDCLOUD: 'c:soundcloud-Whwq7KeY',
  MATOMO: 'c:matomo-zXyeHtHD',
}

export const isConsentManagementAvailable = ($config) => {
  const isConsentManagementAvailable =
    process.client &&
    $config.didomiId &&
    $config.didomiTarget &&
    typeof window.Didomi !== 'undefined' &&
    window.__tcfapi

  if (!isConsentManagementAvailable) {
    // eslint-disable-next-line no-console
    console.warn('Consent management is not available in this environment')
  }

  return !!isConsentManagementAvailable
}

// Sets a listener for consent changes for a specific vendor id. When consent status changes,
// the provided callback is invoked with the new consent status (true/false).
export function setListenerForVendor(vendorId, callback) {
  Didomi.getObservableOnUserConsentStatusForVendor(vendorId).subscribe(function (consentStatus) {
    if (consentStatus === undefined) return
    callback(!!consentStatus)
  })
}

// Utility function to execute a callback when the Didomi SDK is ready. Ensures that Didomi
// related operations are performed only after the SDK has fully loaded.
export const executeWhenDidomiReady = (callback) => {
  window.didomiOnReady = window.didomiOnReady || []
  window.didomiOnReady.push(callback)
}

export function loadDidomiScripts(didomiId, didomiTarget) {
  // https://console.didomi.io/cope-content-performance-group/consent-notices/REkd8TKV/publish
  window.gdprAppliesGlobally = true
  ;(function () {
    function n(e) {
      if (!window.frames[e]) {
        if (document.body && document.body.firstChild) {
          const t = document.body
          const r = document.createElement('iframe')
          r.style.display = 'none'
          r.name = e
          r.title = e
          t.insertBefore(r, t.firstChild)
        } else {
          setTimeout(function () {
            n(e)
          }, 5)
        }
      }
    }
    function e(r, a, o, c, d) {
      function e(e, t, r, n) {
        if (typeof r !== 'function') {
          return
        }
        if (!window[a]) {
          window[a] = []
        }
        let i = false
        if (d) {
          i = d(e, n, r)
        }
        if (!i) {
          window[a].push({ command: e, version: t, callback: r, parameter: n })
        }
      }
      e.stub = true
      e.stubVersion = 2
      function t(n) {
        if (!window[r] || window[r].stub !== true) {
          return
        }
        if (!n.data) {
          return
        }
        const i = typeof n.data === 'string'
        let e
        try {
          e = i ? JSON.parse(n.data) : n.data
        } catch (t) {
          return
        }
        if (e[o]) {
          const a = e[o]
          window[r](
            a.command,
            a.version,
            function (e, t) {
              const r = {}
              r[c] = { returnValue: e, success: t, callId: a.callId }
              if (n.source) {
                n.source.postMessage(i ? JSON.stringify(r) : r, '*')
              }
            },
            a.parameter
          )
        }
      }
      if (typeof window[r] !== 'function') {
        window[r] = e
        if (window.addEventListener) {
          window.addEventListener('message', t, false)
        } else {
          window.attachEvent('onmessage', t)
        }
      }
    }
    e('__tcfapi', '__tcfapiBuffer', '__tcfapiCall', '__tcfapiReturn')
    n('__tcfapiLocator')
    ;(function (e, t) {
      const r = document.createElement('link')
      r.rel = 'preconnect'
      r.as = 'script'
      const n = document.createElement('link')
      n.rel = 'dns-prefetch'
      n.as = 'script'
      const i = document.createElement('link')
      i.rel = 'preload'
      i.as = 'script'
      const a = document.createElement('script')
      a.id = 'spcloader'
      a.type = 'text/javascript'
      a.async = true
      a.charset = 'utf-8'
      let o = 'https://sdk.privacy-center.org/' + e + '/loader.js?target_type=notice&target=' + t
      if (window.didomiConfig && window.didomiConfig.user) {
        const c = window.didomiConfig.user
        const d = c.country
        const s = c.region
        if (d) {
          o = o + '&country=' + d
          if (s) {
            o = o + '&region=' + s
          }
        }
      }
      r.href = 'https://sdk.privacy-center.org/'
      n.href = 'https://sdk.privacy-center.org/'
      i.href = o
      a.src = o
      const f = document.getElementsByTagName('script')[0]
      f.parentNode.insertBefore(r, f)
      f.parentNode.insertBefore(n, f)
      f.parentNode.insertBefore(i, f)
      f.parentNode.insertBefore(a, f)
      //? NOTICE: The line bellow was modified from original script, static values replaced by variables
    })(didomiId, didomiTarget)
  })()
}
