import Vue from 'vue'
import VueSanitize from 'vue-sanitize'

const defaultOptions = {
  allowedTags: ['a', 'em', 'strong', 'u'],
  allowedAttributes: {
    a: ['href'],
  },
}

Vue.use(VueSanitize, defaultOptions)
