import { defaultDataIdFromObject, InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import schema from './fragment-types.json'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: schema,
})

export default ({ req, app }) => {
  return {
    httpEndpoint: process.env.GRAPHQL_URL,
    browserHttpEndpoint: process.env.GRAPHQL_PROXY_URL || process.env.GRAPHQL_URL,
    cache: new InMemoryCache({
      fragmentMatcher,
      dataIdFromObject: (object) => {
        if (object.__typename === 'Image' && object.attributes && object.attributes.description) {
          return defaultDataIdFromObject(object) + ':' + object.attributes.description
        }
        return defaultDataIdFromObject(object)
      },
    }),
  }
}
