const parseJwt = (token) => {
  if (!token) return null
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )
  return JSON.parse(jsonPayload)
}

const getCookie = (cname) => {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

const loadTinypass = ($config) =>
  new Promise((resolve) => {
    if (window.tp && window.tp.user) {
      resolve(window.tp)
    }

    window.tp = window.tp || []
    window.tp.push([
      'init',
      () => {
        window.tp.experience.init()
        resolve(window.tp)
      },
    ])
    const { aid, mode } = $config.tinypass
    const scriptUrl =
      mode === 'prod'
        ? `//experience.tinypass.com/xbuilder/experience/load?aid=${aid}`
        : `//sandbox.tinypass.com/xbuilder/experience/load?aid=${aid}`

    ;(function (src) {
      const a = document.createElement('script')
      a.type = 'text/javascript'
      a.async = true
      a.src = src
      const b = document.getElementsByTagName('script')[0]
      b.parentNode.insertBefore(a, b)
    })(scriptUrl)
  })

const isTinypassAvailable = ($config) => {
  const isTinypassAvailable = process.client && $config.tinypassAid && window.tp

  if (!isTinypassAvailable) {
    // eslint-disable-next-line no-console
    console.warn('Tinypass is not available in this environment')
  }

  return !!isTinypassAvailable
}

export const state = () => {
  return {
    userHasAccess: false,
    user: {},
    isInitialised: false,
  }
}

export const mutations = {
  SET_USER_DATA(state, payload) {
    state.user = payload
    const eventName = 'User status'

    window._mtm = window._mtm || []
    window._mtm.push({ event: 'mat' + eventName, isLoggedIn: !!state.user && !!state.user.aud })
  },
  SET_USER_ACCESS(state, payload) {
    state.userHasAccess = payload
  },
  SET_IS_INITIALISED(state, payload) {
    state.isInitialised = payload
  },
}

export const actions = {
  init({ dispatch, getters, rootGetters }) {
    this.watch(
      () => rootGetters['consent/hasPianoConsent'],
      (hasConsent) => {
        hasConsent && dispatch('execute')
      }
    )

    this.watch(
      () => rootGetters['consent/hasPianoConsent'],
      (hasConsent) => {
        !hasConsent && getters.isLoggedIn && dispatch('logout')
      }
    )
  },
  async execute({ state, commit, dispatch, rootGetters }) {
    if (!process.client || !rootGetters['consent/hasPianoConsent']) {
      return
    }

    dispatch('setTags')

    if (state.isInitialised) {
      window.tp.experience && window.tp.experience.execute()
      return
    }
    await loadTinypass(this.$config)

    const checkAccess = () => {
      dispatch('refreshUser')
      dispatch('checkAccess')
    }

    window.tp.push(['addHandler', 'experienceExecute', checkAccess])
    window.tp.push(['addHandler', 'loginSuccess', checkAccess])
    dispatch('tracking/initCustomPixelEvents', null, { root: true })

    commit('SET_IS_INITIALISED', true)
  },
  setTags({ rootGetters }) {
    if (!rootGetters['consent/hasPianoConsent']) {
      return
    }

    window.tp = window.tp || []
    const tp = window.tp
    const isPaywalled = document.querySelector('#paywall-flag')
    const tags = [isPaywalled ? 'premium' : 'free']
    tp.push(['setTags', tags])
  },
  login({ dispatch, rootGetters }) {
    if (!isTinypassAvailable(this.$config) || !rootGetters['consent/hasPianoConsent']) {
      return
    }

    if (!window.tp.user) {
      return
    }

    window.tp.user.showLogin()
    dispatch('refreshUser')
    window.tp.experience.execute()
  },
  /**
   * Check access to 'Digitalabo' for current user
   */
  async checkAccess({ commit, rootGetters }) {
    if (!isTinypassAvailable(this.$config) || !rootGetters['consent/hasPianoConsent']) {
      return
    }

    // Allow FB access to paywall
    const USERAGENT_STRING = 'UHVibGljYXRpb24tQWNjZXNzLWZvci1GYWNlYm9vaw=='
    if (window.navigator && btoa(window.navigator.userAgent) === USERAGENT_STRING) {
      commit('SET_USER_ACCESS', true)
    } else {
      const tp = window.tp
      try {
        const { data } = await new Promise((resolve) => {
          tp.api.callApi(
            '/access/list',
            {
              aid: this.$config.tinypass.aid,
              expand_bundled: true,
            },
            resolve
          )
        })
        const userRids = data.map((item) => item.resource.rid)
        const hasAccess = this.$config.tinypass.resources.filter((rid) => userRids.includes(rid)).length > 0
        commit('SET_USER_ACCESS', hasAccess)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e)
        commit('SET_USER_ACCESS', false)
      }
    }
  },
  refreshUser({ commit, state }) {
    // needs to be dispatched also when consent is revoked
    if (!isTinypassAvailable(this.$config)) {
      return
    }

    const token = getCookie('__utp') || ''
    const user = parseJwt(token) || {}
    if (user) {
      if (user.aud !== state.user.aud) {
        commit('SET_USER_DATA', user)
      }
    } else if (state.user.aud) {
      commit('SET_USER_DATA', {})
    }
  },
  logout({ dispatch }) {
    // needs to be dispatched also when consent is revoked
    if (!isTinypassAvailable(this.$config)) {
      return
    }

    if (!window.tp.user) {
      return
    }

    window.tp.user.logout()
    dispatch('refreshUser')
    window.tp.experience.execute()
  },
}

export const getters = {
  isLoggedIn: (state) => {
    return !!state.user && !!state.user.aud
  },
  userHasAccess: (state) => {
    return state.userHasAccess
  },
  isInitialised: (state) => state.isInitialised,
  isPianoReady: (state, getters, rootState, rootGetters) =>
    getters.isInitialised && rootGetters['consent/hasPianoConsent'],
}
