<template>
  <NuxtLink :to="link" class="link-button">
    <slot />
  </NuxtLink>
</template>

<script>
export default {
  props: {
    link: {
      type: Object,
      required: true,
    },
  },
}
</script>
