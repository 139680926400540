import { loadMatomo } from '../utils/load-matomo'

const state = () => ({
  isInitialised: false,
  consentGiven: false,
})

const mutations = {
  SET_IS_INITIALISED(state, payload) {
    state.isInitialised = payload
  },
  SET_CONSENT_GIVEN(state, payload) {
    state.consentGiven = payload
  },
}

const actions = {
  async init({ dispatch, rootGetters }) {
    // Initialize Matomo only on the client side
    if (process.client) {
      this.watch(
        () => rootGetters['consent/hasMatomoConsent'],
        (hasConsent) => {
          if (hasConsent) {
            dispatch('execute')
          } else {
            dispatch('revokeConsent')
          }
        }
      )
    }
  },

  async execute({ state, commit, rootGetters }) {
    if (!process.client || !rootGetters['consent/hasMatomoConsent']) {
      return
    }

    // We need this because of this: https://developer.matomo.org/guides/tracking-consent#:~:text=When%20the%20user%20has%20expressed%20they%20no%20longer%20give%20consent%2C%20you%20shall%20not%20call%20the%20following%20method%20anymore%3A
    if (state.isInitialised) {
      return // return when re-consented as per Matomo API guidelines
    }

    await loadMatomo()
    commit('SET_IS_INITIALISED', true)
    commit('SET_CONSENT_GIVEN', true)
  },

  revokeConsent({ commit }) {
    const _paq = (window._paq = window._paq || [])
    _paq.push(['forgetConsentGiven'])
    commit('SET_CONSENT_GIVEN', false)
  },
}

const getters = {
  isInitialised: (state) => state.isInitialised,
  consentGiven: (state) => state.consentGiven,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
