import Vue from 'vue'
import Moment from 'moment'
import { getPortalChannel } from '@/plugins/url-utils'

/*
 ** format date filter
 */
Vue.filter('formatDate', function (value, $config) {
  if (!$config) {
    return ''
  }
  Moment.locale($config.locale.toLowerCase())
  if (!value) {
    return ''
  }
  // FIXME: Need proper ISO value for dates
  // value = new Date(value) // prevent moment deprication warning
  return Moment(value).format('DD. MMMM YYYY')
})

Vue.filter('formatYear', function (value, $config) {
  if (!$config) {
    return ''
  }
  Moment.locale($config.locale.toLowerCase())
  if (!value) {
    return ''
  }
  // FIXME: Need proper ISO value for dates
  // value = new Date(value) // prevent moment deprication warning
  return Moment(value).format('YYYY')
})

Vue.filter('getChannelName', function (value, $config) {
  if (!value) {
    return ''
  }
  const channel = getPortalChannel(value, $config)
  if (channel === null) return ''
  return channel.attributes.name
})
