export const actions = {
  async nuxtServerInit({ dispatch }, { req }) {
    await dispatch('navigation/fetch')
  },
  nuxtClientInit({ dispatch }, context) {
    dispatch('screen/init', context)
    dispatch('consent/init', context)
    dispatch('tracking/init', context)
    dispatch('tinypass/init', context)
    dispatch('matomo/init', context)
  },
}
