'use strict'

import {
  DIDOMI_VENDOR_IDS,
  executeWhenDidomiReady,
  isConsentManagementAvailable,
  loadDidomiScripts,
  setListenerForVendor,
} from '../utils/didomi'

/*
  Integrating Didomi SDK for consent management.
  Ensure that Didomi's SDK is loaded and configured in your project.
*/

// Variables to manage the state of consent data availability check. This is used to ensure
// that consent-related operations are performed only after it's confirmed that user has interacted with the CMP.
let checkDataAvailableResolveFunc = undefined
let checkDataAvailablePromise = undefined

// Function to check if consent data is available, indicating that the user has interacted
// with the consent management platform (CMP). It returns a promise that resolves when the data is available.
export const checkConsentDataBlocking = () => {
  if (!checkDataAvailablePromise)
    checkDataAvailablePromise = new Promise((resolve) => {
      // Create check consent exist function and run it only when didomi is ready
      const checkConsentRun = () => {
        if (!Didomi.shouldUserStatusBeCollected()) {
          // User has already interacted with the CMP
          resolve(true)
        } else {
          checkDataAvailableResolveFunc = resolve
        }
      }

      if (window.Didomi) {
        checkConsentRun()
      } else {
        executeWhenDidomiReady(checkConsentRun)
      }
    })

  return checkDataAvailablePromise
}

// Function to give consent for a specific vendor. It performs a transaction with the Didomi
// SDK to enable consent for the vendor's purposes and commits the transaction.
const giveConsent = async (vendorId) => {
  new Promise((resolve) => {
    const vendorObj = Didomi.getVendorById(vendorId)
    const purposes = vendorObj.purposeIds
    const transaction = Didomi.openTransaction()
    transaction.enablePurposes(...purposes)
    transaction.enableVendor(vendorId)
    transaction.commit()
    resolve(true)
  })
}

export const state = () => ({
  hasPianoConsent: false,
  hasFacebookConsent: false,
  hasInstagramConsent: false,
  hasYoutubeConsent: false,
  hasTwitterConsent: false,
  hasSoundcloudConsent: false,
  hasMatomoConsent: false,
  hasConsentData: false,
})

export const mutations = {
  SET_HAS_PIANO_CONSENT(state, value) {
    state.hasPianoConsent = value
  },
  SET_HAS_FACEBOOK_CONSENT(state, value) {
    state.hasFacebookConsent = value
  },
  SET_HAS_INSTAGRAM_CONSENT(state, value) {
    state.hasInstagramConsent = value
  },
  SET_HAS_YOUTUBE_CONSENT(state, value) {
    state.hasYoutubeConsent = value
  },
  SET_HAS_TWITTER_CONSENT(state, value) {
    state.hasTwitterConsent = value
  },
  SET_HAS_SOUNDCLOUD_CONSENT(state, value) {
    state.hasSoundcloudConsent = value
  },
  SET_HAS_MATOMO_CONSENT(state, value) {
    state.hasMatomoConsent = value
  },
  SET_HAS_CONSENT_DATA(state, value) {
    state.hasConsentData = value
  },
}

export const actions = {
  init({ commit, dispatch }) {
    if (this.$config.didomiId && this.$config.didomiTarget) {
      loadDidomiScripts(this.$config.didomiId, this.$config.didomiTarget)
    }

    window.didomiEventListeners = window.didomiEventListeners || []
    window.didomiEventListeners.push({
      event: 'consent.changed',
      listener: function (context) {
        // Here, when there is checkDataAvailable promise waiting, resolve it immidiately when consent data has been changed for the first time
        if (checkDataAvailableResolveFunc) {
          checkDataAvailableResolveFunc(true)
          checkDataAvailableResolveFunc = undefined
        }
      },
    })

    executeWhenDidomiReady(() => {
      if (!isConsentManagementAvailable(this.$config)) {
        return
      }

      for (const vendor in DIDOMI_VENDOR_IDS) {
        setListenerForVendor(DIDOMI_VENDOR_IDS[vendor], (consented) => commit(`SET_HAS_${vendor}_CONSENT`, consented))
      }

      dispatch('loadConsentDataAvailable')
    })
  },
  async loadConsentDataAvailable({ commit, state }) {
    if (!isConsentManagementAvailable(this.$config)) {
      return
    }

    const hasConsentData = await checkConsentDataBlocking()

    commit('SET_HAS_CONSENT_DATA', !!hasConsentData)
  },
  showConsentManager() {
    if (!isConsentManagementAvailable(this.$config)) {
      return
    }

    Didomi.preferences.show()
  },
  async giveConsentToPiano() {
    if (!isConsentManagementAvailable(this.$config)) {
      return
    }

    await giveConsent(DIDOMI_VENDOR_IDS.PIANO)
  },
  async giveConsentToYoutube({ commit }) {
    if (!isConsentManagementAvailable(this.$config)) {
      return
    }

    await giveConsent(DIDOMI_VENDOR_IDS.YOUTUBE)
  },
  async giveConsentToFacebook() {
    if (!isConsentManagementAvailable(this.$config)) {
      return
    }

    await giveConsent(DIDOMI_VENDOR_IDS.FACEBOOK)
  },
  async giveConsentToInstagram() {
    if (!isConsentManagementAvailable(this.$config)) {
      return
    }

    await giveConsent(DIDOMI_VENDOR_IDS.INSTAGRAM)
  },
  async giveConsentToTwitter() {
    if (!isConsentManagementAvailable(this.$config)) {
      return
    }

    await giveConsent(DIDOMI_VENDOR_IDS.TWITTER)
  },
  async giveConsentToSoundcloud() {
    if (!isConsentManagementAvailable(this.$config)) {
      return
    }

    await giveConsent(DIDOMI_VENDOR_IDS.SOUNDCLOUD)
  },
  async giveConsentToMatomo() {
    if (!isConsentManagementAvailable(this.$config)) {
      return
    }

    await giveConsent(DIDOMI_VENDOR_IDS.MATOMO)
  },
}

export const getters = {
  hasPianoConsent: (state) => state.hasPianoConsent,
  hasFacebookConsent: (state) => state.hasFacebookConsent,
  hasInstagramConsent: (state) => state.hasInstagramConsent,
  hasYoutubeConsent: (state) => state.hasYoutubeConsent,
  hasTwitterConsent: (state) => state.hasTwitterConsent,
  hasSoundcloudConsent: (state) => state.hasSoundcloudConsent,
  hasMatomoConsent: (state) => state.hasMatomoConsent,
  hasConsentData: (state) => state.hasConsentData,
}
