import Vue from 'vue'

export const state = () => ({
  isInitialized: false,
  screen: {},
})

export const mutations = {
  INIT(state) {
    Vue.set(state, 'isInitialized', true)
  },
  SET(state, screen) {
    Vue.set(state, 'screen', screen)
  },
}

export const actions = {
  init({ commit, state }) {
    if (state.isInitialized) {
      return
    }

    const onWindowResized = () => {
      commit('SET', {
        width: window.innerWidth,
        type:
          window.innerWidth < 767
            ? 'xs'
            : window.innerWidth < 1023
            ? 'sm'
            : window.innerWidth < 1200
            ? 'md'
            : window.innerWidth < 1300
            ? 'lg'
            : window.innerWidth < 1440
            ? 'xl'
            : '2xl',
      })
    }

    window.addEventListener('resize', onWindowResized)
    setTimeout(onWindowResized, 0)

    commit('INIT')
  },
}

export const getters = {
  screenWidth(state) {
    return state.screen.width
  },
  screenType(state) {
    return state.screen.type
  },
}
