import Vue from 'vue'
import NAVIGATION from '@/gql/navigation.gql'

export const state = () => ({
  data: [],
  isCached: false,
})

export const mutations = {
  SET(state, data) {
    Vue.set(state, 'data', data)
    Vue.set(state, 'isCached', true)
  },
}

export const actions = {
  async fetch({ commit, state }) {
    // FIXME: Better caching strategy
    if (state.isCached) return
    const client = this.app.apolloProvider.defaultClient
    const { data } = await client.query({
      query: NAVIGATION,
      variables: {
        portalId: parseInt(this.$config.portalId),
      },
    })
    commit('SET', data.getNavigationItemsByPortalId.data)
  },
}

export const getters = {
  headerNavigation(state) {
    // console.log(state.data)
    let children = state.data.length ? state.data[0].attributes.children : []
    children = children.filter((child) => child.attributes.link.attributes.text === 'header-main')
    return children[0] ? children[0].attributes.children || [] : []
  },
  footerNavigationTop(state) {
    let children = state.data.length ? state.data[0].attributes.children : []
    children = children.filter((child) => child.attributes.link.attributes.text === 'footer-top')
    return children[0] ? children[0].attributes.children || [] : []
  },
  footerNavigationBottom(state) {
    let children = state.data.length ? state.data[0].attributes.children : []
    children = children.filter((child) => child.attributes.link.attributes.text === 'footer-bottom')
    return children[0] ? children[0].attributes.children || [] : []
  },
  dossierNavigation(state) {
    let children = state.data.length ? state.data[0].attributes.children : []
    children = children.filter((child) => child.attributes.link.attributes.text === 'dossiers')
    return children[0] ? children[0].attributes.children || [] : []
  },
}
