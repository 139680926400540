import { checkConsentDataBlocking } from './consent'
import { loadScript } from '../utils/load-script'

const copeNetworkId = 1003
const copeTimeout = 1000
const copeSiteId = '609973'
const copeScriptUrl = `https://apps.sascdn.com/mh_audience/sdo_sas.js`
const copeSmartBaseUrl = 'https://styria.smartadserver.com'

export const state = () => {
  return {
    isInitialised: false,
    noAdPositions: [],
  }
}

export const mutations = {
  SET_IS_INITIALISED(state, payload) {
    state.isInitialised = payload
  },
  SET_NOAD_POSITIONS(state, payload) {
    state.noAdPositions.push(payload)
  },
}

export const actions = {
  init() {
    if (!this.$config.adsEnabled) {
      return Promise.resolve(false)
    }
    if (this.loadCopeSmartAdserverPromise) {
      return this.loadCopeSmartAdserverPromise
    }
    this.loadCopeSmartAdserverPromise = loadScript({ src: copeScriptUrl })
    return this.loadCopeSmartAdserverPromise
  },

  async load({ commit, dispatch, getters, state }, { pageName, formats, target }) {
    await checkConsentDataBlocking()
    await dispatch('init')

    window._sasConfig = {
      networkid: copeNetworkId,
      timeOut: copeTimeout, // max. Partner timeout (ms)
      enableLogging: false,
      placement: {
        siteId: copeSiteId,
        pageName: pageName, // pageName - cms based data
        formats: formats, // [{ id: 22375 }, { id: 22373 }, { id: 22374 }],
        /**
         *
         * Formats - Desktop
         * SuperBanner-FullBanner1 (728x90): 22374
         * SuperBanner-Fullbanner2 (728x90): 23481
         * SuperBanner-Fullbanner3(728x90): 23482
         * Billboard (970x250 px oder 950x250 px): 23170
         * MedRec1 (300x250 px): 22373
         * MedRec2 (300x250 px): 23175
         * MedRec3 (300x250 px): 23176
         * MedRec4 (300x250px): 48345
         * Sitelink1 (0x0)  - Bild-Text Kombination: 23167
         * Sitelink2 (0x0) - Bild-Text Kombination: 99048
         * Sitebar/HPA/Sky (300x600 px, 160x600 px): 22375
         * Sticky 300 – Right (300x600)96370
         *
         *
         * Formats - Mobile
         *
         * mma-banner1-web: 23362
         * mma-banner2-web: 23366
         * mma-banner3-web: 23367
         * mma-banner4-web: 48368
         *
         */
        target: target, // target - cms based data
      },
      partners: {},
    }
    window.sas = window.sas || {}
    window.sas.cmd = window.sas.cmd || []

    if (!state.isInitialised) {
      window.sas.cmd.push(function () {
        window.sas.setup({
          networkid: copeNetworkId,
          domain: copeSmartBaseUrl, // Smart BaseURL
          async: true,
          renderMode: 2,
        })
      })
    } else {
      window?.sas?.call('onecall', {
        siteId: copeSiteId,
        pageName: pageName,
        formats: formats,
        target: target,
      })
    }

    commit('SET_IS_INITIALISED', true)

    formats.forEach((format) => {
      if (document.getElementById('sas_' + format.id) !== null) {
        window?.sas?.cmd.push(function () {
          window?.sas?.render(format.id)
        })
        window?.sas?.cmd.push(function () {
          window?.sas?.call(
            'std',
            { siteId: copeSiteId, pageName: pageName, formatId: format.id },
            {
              onNoad: function (data) {
                if (data.formatId) {
                  commit('SET_NOAD_POSITIONS', data)
                }
              },
            }
          )
        })
      }
    })
  },
}

export const getters = {
  isInitialised: (state) => state.isInitialised,
  noAdPositions: (state) => state.noAdPositions,
}
