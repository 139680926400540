<template>
  <div class="furche-page">
    <div class="furche-page__content">
      <Nuxt />
      <PageFooter class="footer" />
      <InlineContainerStickyBannerVue></InlineContainerStickyBannerVue>
      <PortalTarget name="modals" multiple />
    </div>
    <div class="furche-page__outside">
      <SkyScraper desktop-id="sas_22375" :ads-enabled="adsEnabled" />
    </div>
  </div>
</template>

<script>
import InlineContainerStickyBannerVue from '@/components/footer/InlineContainerStickyBanner'
import PageFooter from '@/components/footer/default'
import SkyScraper from '@/components/ads/skyscraper'
export default {
  components: {
    PageFooter,
    InlineContainerStickyBannerVue,
    SkyScraper,
  },
  computed: {
    adsEnabled() {
      return this.$config.adsEnabled
    },
  },
}
</script>

<style lang="scss">
@import '../css/import/vars';

.furche-page {
  display: grid;
  grid-template-columns: minmax($page-min-width, $page-max-width) 1fr;
  grid-template-rows: auto;
  gap: 0;

  @media all and (min-width: $screen-width-767) {
    gap: 20px;
  }

  &__outside {
    display: none;

    @media screen and (min-width: 1875px) {
      // we make sure that we got a minimum space of 400px to the right from the content so the sitebar ad will be counted as visible
      display: block;
    }
  }
}
</style>
